<template>
  <div class="settlement">
    <div class="back" @click="back">
      <img
        class="icon_back"
        src="https://www.zizhihelp.com/upload/static/web/mine_fanhui.png"
        alt=""
      />
      <span>返回</span>
    </div>

    <el-form :model="form" :rules="rules" ref="ruleForm" label-width="100px">
      <div class="public_title">基本信息</div>

      <el-form-item label="企业名称" prop="companyName">
        <el-autocomplete
          v-model="form.companyName"
          :fetch-suggestions="querySearchAsync"
          placeholder="请输入企业名称进行搜索"
          @select="handleSelect"
          style="width: 100%"
          highlight-first-item
        ></el-autocomplete>
      </el-form-item>

      <el-form-item label="营业执照" prop="businessLicenseUrl">
        <el-upload
          class="avatar-uploader"
          action="https://zizhihelp.com/webapi/file/upload2"
          :show-file-list="false"
          :on-success="handleUploadSuccess"
          :before-upload="beforeUpload"
          accept=".jpg,.jpeg,.png"
          :headers="headers"
        >
          <img
            v-if="form.businessLicenseUrl"
            :src="form.businessLicenseUrl"
            class="avatar"
          />
          <i v-else class="el-icon-plus avatar-uploader-icon"></i>
        </el-upload>

        <div style="color: #999">
          <i class="el-icon-info"></i>
          请上传2M以内jpg/png格式的图片
        </div>
      </el-form-item>

      <el-form-item label="真实姓名" prop="contractName">
        <el-input
          v-model="form.contractName"
          placeholder="请输入真实姓名"
        ></el-input>
      </el-form-item>

      <el-form-item label="联系方式" prop="contractPhone">
        <el-input
          v-model="form.contractPhone"
          placeholder="请输入联系方式"
        ></el-input>
      </el-form-item>

      <el-form-item label="联系微信" prop="contractWx">
        <el-input
          v-model="form.contractWx"
          placeholder="请输入联系微信"
        ></el-input>
      </el-form-item>

      <div class="public_title">详细信息</div>

      <el-form-item label="合作方式" prop="cooperationType">
        <el-input
          v-model="form.cooperationType"
          placeholder="请输入合作方式"
        ></el-input>
      </el-form-item>

      <el-form-item label="可合作区域" prop="cooperativePlace">
        <el-input
          v-model="form.cooperativePlace"
          placeholder="请输入可合作区域"
        ></el-input>
      </el-form-item>

      <el-form-item label="合作费用" prop="cooperativeCost">
        <el-input
          v-model="form.cooperativeCost"
          placeholder="请输入合作费用"
        ></el-input>
      </el-form-item>

      <el-form-item label="公司优劣势" prop="good">
        <el-input
          type="textarea"
          :rows="3"
          placeholder="请输入公司优劣势"
          v-model="form.good"
        >
        </el-input>
      </el-form-item>

      <el-form-item label="">
        <el-button type="primary" @click="submit">提交</el-button>
        <el-button @click="back">取消</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
/* 企业入驻 */
import local from "@/utils/local"; // 工具函数
import { searchCompany } from "@/api/itemizedQuery.js"; // 接口
import { companyInSave } from "@/api/home.js"; // 接口

export default {
  data() {
    return {
      form: {
        companyName: "", // 公司名称
        companyId: 0, // 公司ID
        businessLicenseUrl: "", // 营业执照
        contractName: "", // 联系人名称
        contractPhone: "", // 联系人电话号
        contractWx: "", // 联系人微信号
        cooperationType: "", // 合作方式
        cooperativePlace: "", // 可合作区域
        cooperativeCost: "", // 加盟费用
        good: "", // 公司优劣势
        wxUserId: "", // 微信用户ID
        auditStatus: "待审核", // 审核状态
      },
      rules: {
        companyName: [
          {
            required: true,
            message: "请输入企业名称",
            trigger: ["blur", "change"],
          },
        ],
        businessLicenseUrl: [
          { required: true, message: "请上传营业执照", trigger: "blur" },
        ],
        contractName: [
          { required: true, message: "请输入真实名称", trigger: "blur" },
        ],
        contractPhone: [
          { required: true, message: "请输入联系电话", trigger: "blur" },
        ],
        contractWx: [
          { required: true, message: "请输入联系微信", trigger: "blur" },
        ],
        cooperationType: [
          { required: true, message: "请输入合作方式", trigger: "blur" },
        ],
        cooperativePlace: [
          { required: true, message: "请输入可合作区域", trigger: "blur" },
        ],
        cooperativeCost: [
          { required: true, message: "请输入合作费用", trigger: "blur" },
        ],
        good: [
          { required: true, message: "请输入公司优劣势", trigger: "blur" },
        ],
      },

      // 图片上传的配置信息,如token等配置信息
      headers: {
        token: localStorage.getItem("token") || "",
      },
    };
  },

  methods: {
    /* 操作 */
    // 提交
    submit() {
      this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
          const params = {
            ...this.form,
            wxUserId: local.get("userInfo").id,
          };
          const res = await companyInSave([params]);
          if (res.code === 0) {
            this.$message.success("提交成功！");
            this.$emit("change", true);
          } else {
            this.$message.error(res.msg);
          }
        }
      });
    },
    // 取消提交
    back() {
      this.$emit("change", false);
    },
    // 营业执照上传
    handleUploadSuccess(res, file) {
      if (res.name) {
        this.form.businessLicenseUrl = res.url;
        this.$refs.ruleForm.validateField("businessLicenseUrl");
        this.$message.success("上传成功！");
      } else {
        this.$message.error("上传失败，请重试");
      }
    },
    // 文件上传前
    beforeUpload(file) {
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isLt2M) {
        this.$message.error("上传图片大小不能超过 2MB!");
      }
      return isLt2M;
    },

    // 查询企业名称-从服务器获取
    async querySearchAsync(queryString, cb) {
      const params = {
        name: queryString, // 企业名称
        pageNum: 1,
        pageSize: 50,
        queryType: "fulltext", // 检索模式
        total: 50,
      };

      const { data } = await searchCompany(params);
      data.records = data.records.map((v) => {
        return { value: v.name, id: v.id };
      });
      cb(data.records);
    },
    // 选中企业名称选项
    handleSelect(item) {
      this.form.companyId = item.id;
    },
  },
};
</script>

<style lang="less" scoped>
.settlement {
  background-color: #fff;

  /* 头像 */
  /deep/.avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409eff;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 300px;
    height: 180px;
    line-height: 180px;
    text-align: center;
  }
  .avatar {
    width: 300px;
    height: 180px;
    display: block;
  }

  .back {
    display: flex;
    align-items: center;
    color: #4f7bff;
    font-size: 16px;
    // font-weight: bold;
    margin-bottom: 20px;
    cursor: pointer;
    width: fit-content;

    .icon_back {
      width: 16px;
      margin-right: 10px;
    }
  }
}
</style>
