<template>
  <div>
    <div v-if="!addShow">
      <el-form ref="form" :model="form" label-width="72px">
        <el-row :gutter="20">
          <el-col :span="6">
            <el-form-item label="审核状态">
              <el-select
                style="width: 100%"
                v-model="form.auditStatus"
                placeholder="请选择审核状态"
                clearable
                @change="query"
              >
                <el-option
                  v-for="item in auditStatusOptions"
                  :key="item.name"
                  :label="item.name"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :offset="12" :span="6" class="tar">
            <el-button @click="addCompany" type="primary"> 添加公司 </el-button>
          </el-col>
        </el-row>
      </el-form>

      <el-table :data="tableData" v-loading="loading" style="width: 100%">
        <el-table-column prop="name" label="公司名称" min-width="150">
          <template slot-scope="scope">
            <div class="flex alc">
              <img
                class="icon_vip"
                src="https://www.zizhihelp.com/upload/static/web/mine_jianzhu.png"
                alt=""
              />
              <div class="c333 fwb">{{ scope.row.companyName }}</div>
            </div>
          </template>
        </el-table-column>

        <el-table-column prop="createTime" label="创建时间"> </el-table-column>

        <el-table-column prop="auditStatus" label="状态">
          <template slot-scope="scope">
            <div
              :class="
                scope.row.auditStatus == '待审核'
                  ? 'ce3991d'
                  : scope.row.auditStatus == '已驳回'
                  ? 'ceb4f3d'
                  : 'c28c445'
              "
            >
              {{ scope.row.auditStatus }}
            </div>
          </template>
        </el-table-column>

        <el-table-column prop="auditStatus" label="操作" min-width="60">
          <template slot-scope="scope">
            <div class="link" @click="toDetails(scope.row)">查看详情</div>
          </template>
        </el-table-column>
      </el-table>

      <el-pagination
        class="pagination"
        hide-on-single-page
        layout="prev, pager, next"
        :total="total"
        @current-change="handleCurrentChange"
        :current-page="form.pageNum"
        :page-size="form.pageSize"
      >
      </el-pagination>

      <!-- 详情 -->
      <customDialog v-model="detailsShow" title="公司详情" width="1120px">
        <div class="table">
          <div class="flex row">
            <div class="desc_item">
              <div class="label">企业名称</div>
              <div class="value">
                {{ details.companyName | noVoid }}
              </div>
            </div>

            <div class="desc_item">
              <div class="label">营业执照</div>
              <div class="value">
                <el-image
                  class="avatar"
                  :src="details.businessLicenseUrl"
                  :preview-src-list="[details.businessLicenseUrl]"
                >
                </el-image>
              </div>
            </div>
          </div>

          <div class="flex row">
            <div class="desc_item">
              <div class="label">真实姓名</div>
              <div class="value">
                {{ details.contractName | noVoid }}
              </div>
            </div>

            <div class="desc_item">
              <div class="label">联系方式</div>
              <div class="value">
                {{ details.contractPhone | noVoid }}
              </div>
            </div>
          </div>

          <div class="flex row">
            <div class="desc_item">
              <div class="label">联系微信</div>
              <div class="value">
                {{ details.contractWx | noVoid }}
              </div>
            </div>

            <div class="desc_item">
              <div class="label">合作方式</div>
              <div class="value">
                {{ details.cooperationType | noVoid }}
              </div>
            </div>
          </div>

          <div class="flex row">
            <div class="desc_item">
              <div class="label">可合作区域</div>
              <div class="value">
                {{ details.cooperativePlace | noVoid }}
              </div>
            </div>

            <div class="desc_item">
              <div class="label">合作费用</div>
              <div class="value">
                {{ details.cooperativeCost | noVoid }}
              </div>
            </div>
          </div>

          <div class="flex row">
            <div class="desc_item">
              <div class="label">公司优劣势</div>
              <div class="value">
                {{ details.good | noVoid }}
              </div>
            </div>
          </div>
        </div>
      </customDialog>
    </div>

    <add @change="handleAddChange" v-if="addShow" />
  </div>
</template>

<script>
/* 个人中心-订单记录 */
import { getMyCompanyList } from "@/api/personalCenter";
import { getDict } from "@/api/common";
import local from "@/utils/local";
import add from "@/views/settlement/add.vue";

export default {
  components: { add },

  data() {
    return {
      form: {
        auditStatus: "",
        pageNum: 1,
        pageSize: 9,
        wxUserid: "",
      },
      auditStatusOptions: [],
      tableData: [],
      total: 0,
      details: {},
      detailsShow: false,
      addShow: false,
      loading: false,
    };
  },

  methods: {
    /* 获取数据 */
    // 获取列表数据
    async getListData() {
      this.loading = true;
      let params = { ...this.form, wxUserid: local.get("userInfo").id };
      const { code, data, msg } = await getMyCompanyList(params);
      if (code !== 0) return this.$message.error(msg);
      this.tableData = data.records;
      this.total = data.total;
      this.loading = false;
    },
    // 获取字典数据
    async getOptions() {
      const auditStatusOptions = await getDict({ source: "distributionFlag" });
      this.auditStatusOptions = auditStatusOptions.data;
    },

    /* 操作 */
    // 添加公司回调
    handleAddChange(flag) {
      this.addShow = false;
      if (flag) {
        this.getListData();
      }
    },

    // 查看详情
    toDetails(row) {
      this.details = { ...row };
      this.detailsShow = true;
    },
    // 添加公司
    addCompany() {
      this.addShow = true;
    },
    // 分页
    handleCurrentChange(val) {
      this.form.pageNum = val;
      this.getListData();
    },
    // 查询
    query() {
      this.form.pageNum = 1;
      this.getListData();
    },

    /* 初始化 */
    __init__() {
      this.getListData();
      this.getOptions();
    },
  },

  created() {
    this.__init__();
  },
};
</script>

<style lang="less" scoped>
.icon_vip {
  width: 42px;
  height: 42px;
  margin-right: 10px;
}

/deep/.el-table__header-wrapper th {
  background-color: #f9fafc !important;
  color: #4f7bff !important;
}

/deep/.avatar {
  // width: 300px;
  height: 180px;
  display: block;
}

/deep/.el-button--primary {
  background-color: #4f7bff;
  border-color: #4f7bff;
}
</style>
